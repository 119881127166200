.navbar-nav:hover{
    color: #f8f9fa;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    transition: 0.5s;
}

a:hover{
    color: #f8f9fa  !important;
}

.dropdown-item:hover{
    color: rgb(0, 0, 0)  !important;
}

canvas{
   height: 70vh;
}